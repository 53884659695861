@use 'src/styles/base/colors' as *;
@use 'src/styles/base/variables' as *;
@use 'src/styles/base/mixins' as *;
@use 'src/styles/forms/forms-mixins' as *;

.dropdown-toggle {
  position: relative;

  .dropdown {
    display: none;
    min-width: 200px;
    position: absolute;
    right: 0;
    z-index: $user-dropdown-index;
    background-color: $color-FFFFFF;
    border: 1px solid $color-F1F1F1;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

    &.show {
      display: block;
    }

    .button {
      height: 40px;
      width: 100%;
      background-color: $color-FFFFFF;

      &:hover {
        background-color: $color-F8F8F8;
      }

      .icon {
        @include icon-size(20px);

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}

.form-control-dropdown,
.form-control-dropdown-multiselect {
  @include form-control-dropdown;
}

.form-control-dropdown {
  &.is-inline-label {
    flex-direction: row;
    align-items: center;

    .label {
      margin-right: 10px;
    }

    .dropdown {
      min-width: 180px;
    }
  }
}

.form-control-dropdown-multiselect {
  &.is-options-limit-reached {
    .dropdown-menu-item {
      &:not(.is-selected) {
        cursor: not-allowed;
        color: $color-D2D1D1;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .dropdown-multiselect-selected-options-container {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-right: 6px;
  }

  .selected-options {
    overflow: hidden;
    margin-right: 8px;
    white-space: nowrap;
  }

  .selected-options-count {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    min-width: 22px;
    padding: 1px 6px;
    border-radius: 4px;
    background: $color-E30613;
    color: $color-FFFFFF;
  }
}

.workshops-filters,
.users-filters,
.news-filters {
  .selected-options {
    display: block;
    text-overflow: ellipsis;
  }
}
