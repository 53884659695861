@use 'src/styles/base/colors' as *;
@use 'src/styles/forms/forms-mixins' as *;

.form-control-password {
  &.is-visible {
    .button-toggle-visibility {
      .icon-eye-on-525050-24x24 {
        display: none;
      }

      .icon-eye-off-525050-24x24 {
        display: block;
      }
    }
  }

  &.is-error {
    .input {
      border-color: $color-FF3A0F;
    }
  }

  .label {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    padding: 0 1px;
  }

  .input-wrapper {
    @include input-wrapper;

    .input-suffix {
      display: flex;
      padding: 0;
    }
  }

  .button-toggle-visibility {
    width: 100%;
    height: 100%;
    min-width: auto;
    background-color: $color-FFFFFF;
    pointer-events: auto;

    &:hover {
      background-color: $color-FFFFFF;
    }

    .icon {
      justify-content: center;
      background-color: $color-525050;

      &:first-child {
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }
    }

    .icon-eye-on-525050-24x24,
    .icon-eye-off-525050-24x24 {
      width: 24px;
      height: 24px;
      margin: 0;
    }

    .icon-eye-on-525050-24x24 {
      display: block;
    }

    .icon-eye-off-525050-24x24 {
      display: none;
    }
  }
}
