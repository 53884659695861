@use 'src/styles/base/colors' as *;
@use 'src/styles/base/variables' as *;

$dropdown-menu-index: $form-control-dropdown-index;
$dropdown-menu-search-box-height: 40px;
$dropdown-menu-max-height: 300px;

@mixin input {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid $color-E7E7E7;
  background-color: $color-FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  color: $color-282525;
  outline: 0;

  &::placeholder {
    color: $color-A7A6A6;
  }

  &:focus {
    border-color: $color-E30613;
  }

  &:disabled {
    color: $color-D2D1D1;
    border-color: $color-E7E7E7;
    background-color: $color-F8F8F8;
  }
}

@mixin input-wrapper {
  position: relative;

  &.is-suffix {
    .input {
      padding-right: 60px;
    }

    .input-suffix {
      display: flex;
      width: fit-content;
    }
  }

  .input-suffix {
    display: none;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    width: 60px;
    padding: 0 10px;
    border-radius: 0 12px 12px 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: $color-A7A6A6;
    pointer-events: none;
  }
}

@mixin dropdown-menu-item {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: $color-525050;
  cursor: pointer;

  &:hover {
    background-color: $color-FEEFF0;
  }

  &.is-selected {
    background-color: $color-FEEFF0;
  }
}

@mixin dropdown-menu {
  display: none;
  z-index: $dropdown-menu-index;
  overflow: hidden;
  min-width: 100%;
  max-height: $dropdown-menu-max-height;
  border: 1px solid $color-F1F1F1;
  border-radius: 6px;
  background: $color-FFFFFF;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

  &.show {
    display: block;
    inset: 5px auto auto 0 !important;
  }

  .dropdown-menu-item {
    @include dropdown-menu-item;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-height: 40px;
    padding: 10px;
    box-sizing: border-box;
    word-break: break-word;

    .option-label {
      width: 100%;
    }
  }
}

@mixin form-control-dropdown {
  &.is-error {
    .dropdown-toggle {
      border-color: $color-FF3A0F;

      &:focus,
      &.is-open {
        border-color: $color-FF3A0F;
      }
    }

    .search-box {
      .input {
        border-color: $color-D2D1D1;
        color: $color-282525;
      }
    }
  }

  &.is-search {
    .ng-scrollbar {
      max-height: calc(#{$dropdown-menu-max-height} - #{$dropdown-menu-search-box-height});
    }

    .search-box {
      display: block;
    }
  }

  .dropdown-container {
    position: relative;
  }

  .dropdown-toggle {
    @include input;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0;
    background: $color-FFFFFF;

    &:focus {
      border-color: $color-D2D1D1;
    }

    &.is-open {
      .arrow-triangle-down-gray {
        transform: rotate(180deg);
      }

      .dropdown-menu {
        display: block;
      }
    }

    .clear-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .close-gray-icon {
      width: 16px;
      height: 16px;
      mask-size: 16px 16px;
      background-color: $color-525050;
    }

    .arrow-triangle-down-gray-icon {
      display: block;
      width: 24px;
      min-width: 24px;
      height: 24px;
      mask-size: 24px 24px;
      background-color: $color-525050;
    }
  }

  .dropdown-toggle-label {
    overflow: hidden;
    display: flex;
    gap: 2px;
    width: 100%;
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }

  .dropdown-menu {
    @include dropdown-menu;
  }

  .ng-scrollbar {
    max-height: $dropdown-menu-max-height;
  }

  .search-box {
    display: none;

    .input {
      height: $dropdown-menu-search-box-height;
      padding-right: 36px;
      border-width: 0 0 1px 0;
      border-color: $color-D2D1D1;
      border-radius: 0;
    }
  }

  .search-no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 104px;
    padding: 0 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: $color-A7A6A6;
  }
}
