@use 'colors' as *;

$default-font-family: 'Lato', sans-serif;
$default-font-size: 14px;
$default-font-weight: 500;
$default-font-color: $color-282525;

$modal-backdrop-index: 1055;
$modal-content-index: 1056;

$form-control-dropdown-index: 999;
$user-dropdown-index: 999;

$paginator-button-width: 35px;
$paginator-button-height: 36px;

$table-row-cell-min-height: 60px;
$table-column-min-width: 100px;
$table-column-first-last-max-width: 280px;
$table-column-max-width: 270px;
$table-details-column-min-width: 100px;
$table-details-column-max-width: 250px;
$table-details-column-gap: 15px 20px;

$sidebar-anim-time: 200ms;
$sidebar-anim-type: cubic-bezier(0.51, 0.92, 0.24, 1.15);
