@use 'src/styles/base/colors' as *;
@use 'src/styles/forms/forms-mixins' as *;

input[type='text'],
input[type='number'],
input[type='phone'],
input[type='email'],
input[type='password'] {
  @include input;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.form-control-input-range {
  .input-range-container {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  span {
    display: flex;
    min-width: 8px;
  }

  .form-control {
    width: 100%;
  }
}

.form-control-input {
  &.is-error {
    .input {
      border-color: $color-FF3A0F;
    }
  }

  .input-wrapper {
    @include input-wrapper;
  }
}

.auth-input-fields-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
}
