@use 'src/styles/base/colors' as *;
@use 'src/styles/base/variables' as *;
@use 'src/styles/base/mixins' as *;

$padding: 16px;
$header-padding: 20px 16px;
$modal-small-width: 500px;

body {
  &.modal-open {
    padding: 0 !important;
  }
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $modal-content-index;
  overflow-y: auto;
  display: none;
  justify-content: center;
  align-items: center;
  padding: $header-padding;

  &.show {
    display: grid;
  }

  &.small {
    .modal-dialog {
      width: $modal-small-width;
    }

    .modal-container {
      .modal-header {
        padding-bottom: 10px;

        .modal-title {
          @include font-size-small;
        }
      }

      .modal-content {
        border: none;
        border-top: 1px solid $color-F1F1F1;
        border-bottom: 1px solid $color-F1F1F1;

        &.has-content-side-padding {
          padding: 16px 16px 32px;
        }
      }

      .modal-footer {
        border: none;
      }
    }
  }

  .modal-container {
    &.is-header {
      .modal-header {
        display: flex;
        justify-content: space-between;

        .close-button {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }

    &.is-footer {
      .modal-footer {
        display: flex;
      }
    }

    .modal-header {
      display: none;
      box-sizing: border-box;
      padding: $padding;

      .modal-title {
        @include font-size-medium-large;
      }
    }

    .modal-content {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      padding: $padding;
      border-bottom: 1px solid $color-E7E7E7;
      border-top: 1px solid $color-E7E7E7;

      &.has-content-side-padding {
        padding: $padding;
      }

      .modal-scrollbar {
        max-height: calc(100vh - 228px) !important;
      }
    }

    .modal-footer {
      display: none;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: $padding;
    }
  }
}

.modal-dialog {
  background-color: $color-FFFFFF;
  border: 1px solid $color-F1F1F1;
  box-shadow: 0 2px 10px rgba($color-000000, 0.1);
  border-radius: 6px;
  box-sizing: content-box;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $color-000000;
  opacity: 0.2;
  z-index: $modal-backdrop-index;
}
