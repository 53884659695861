@use 'src/styles/base/colors' as *;
@use 'src/styles/forms/inputs';
@use 'src/styles/forms/textareas';
@use 'src/styles/forms/passwords';
@use 'src/styles/forms/checkboxes';
@use 'src/styles/forms/dropdowns';
@use 'src/styles/forms/autocompletes';
@use 'src/styles/forms/datepickers';

.form-control {
  &.is-error {
    .label {
      color: $color-E30613;
    }

    .input,
    .textarea {
      border-color: $color-FF3A0F;
      color: $color-FF3A0F;
    }

    .errors-list {
      display: block;
    }
  }

  .label {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    padding: 0 1px;
    display: flex;
    gap: 2px;

    &.is-required {
      &:after {
        content: '*';
        display: block;
        color: $color-E30613;
      }
    }
  }

  .errors-list {
    display: none;
  }
}

.required-mark {
  color: $color-E30613;
}

.filters-box {
  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .filters-form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .datepicker-form-control,
    .form-control {
      width: 248px;
    }

    .form-control-datepicker-range {
      width: 516px;
    }
  }
}
