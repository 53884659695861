@use 'src/styles/base/colors' as *;

$button-default-height: 40px;
$button-default-padding: 0 15px;
$button-default-border-radius: 4px;
$button-default-icon-width: 18px;
$button-default-icon-height: 18px;
$button-icon-default-min-width: 40px;
$button-icon-default-padding: 0;
$button-icon-default-border-radius: 6px;
$button-icon-default-icon-width: 24px;
$button-icon-default-icon-height: 24px;

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  height: $button-default-height;
  padding: $button-default-padding;
  border: 0;
  border-radius: $button-default-border-radius;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
  outline: none;

  &.is-disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  &.red {
    background-color: $color-E30613;
    color: $color-FFFFFF;

    &:hover {
      background-color: $color-CD0511;
    }

    &.is-disabled,
    &[disabled] {
      background-color: $color-E7E7E7;
    }

    .icon {
      background-color: $color-FFFFFF;
    }
  }

  &.black {
    background-color: $color-525050;
    color: $color-FFFFFF;

    &:hover {
      background-color: $color-282525;
    }

    &.is-disabled,
    &[disabled] {
      background-color: $color-E7E7E7;
    }

    .icon {
      background-color: $color-FFFFFF;
    }
  }

  &.red-outline {
    border: 1px solid $color-E30613;
    background-color: $color-FFFFFF;
    color: $color-E30613;

    &:hover {
      border-color: $color-CD0511;
      color: $color-CD0511;

      .icon {
        background-color: $color-CD0511;
      }
    }

    &.is-disabled,
    &[disabled] {
      border-color: $color-E7E7E7;
      color: $color-E7E7E7;

      .icon {
        background-color: $color-E7E7E7;
      }
    }

    .icon {
      background-color: $color-E30613;
    }
  }

  &.black-outline {
    border: 1px solid $color-525050;
    background-color: $color-FFFFFF;
    color: $color-525050;

    &:hover {
      border-color: $color-282525;
      color: $color-282525;

      .icon {
        background-color: $color-282525;
      }
    }

    &.is-disabled,
    &[disabled] {
      border-color: $color-E7E7E7;
      color: $color-E7E7E7;

      .icon {
        background-color: $color-E7E7E7;
      }
    }

    .icon {
      background-color: $color-525050;
    }
  }

  .icon {
    display: block;
    width: $button-default-icon-width;
    height: $button-default-icon-height;
    mask-size: $button-default-icon-width $button-default-icon-height;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}

.button-icon {
  @extend .button;

  justify-content: center;
  width: $button-icon-default-min-width;
  padding: $button-icon-default-padding;
  border-radius: $button-icon-default-border-radius;
  background-color: $color-FBFBFB;

  &:hover {
    background-color: $color-E7E7E7;
  }

  &.is-active {
    background-color: $color-E30613;

    .icon {
      background-color: $color-FFFFFF;
    }
  }

  .icon {
    display: block;
    width: $button-icon-default-icon-width;
    height: $button-icon-default-icon-height;
    mask-size: $button-icon-default-icon-width $button-icon-default-icon-height;
    background-color: $color-7D7B7B;
  }
}

.button-icon-circle {
  width: 26px;
  height: 26px;
  background: $color-FFFFFF;
  box-shadow: 0 2px 20px rgba($color-000000, 0.1);
  border-radius: 50%;
  flex: none;
  order: 0;
  flex-grow: 0;

  &.is-disabled,
  &[disabled] {
    background-color: $color-F1F1F1;
  }

  .icon {
    background-color: $color-A7A6A6;
  }
}

.link {
  color: $color-E30613;
  text-decoration: none;

  &:hover {
    color: $color-CD0511;
  }
}
