@use 'src/styles/base/colors' as *;

.rx-container {
  border-radius: 10px !important;

  .rx-toolbar-container.is-rx-toolbar {
    border-radius: 10px 10px 0 0 !important;
  }

  .rx-toolbar:first-child {
    border-radius: 10px 0 0 0 !important;
  }

  .rx-button {
    &:hover {
      background-color: $color-F8F8F8;
    }

    &.active {
      background-color: $color-FFF6F6;

      .rx-button-icon {
        background: none;
        border: none;
        color: $color-E30613;
      }
    }

    &.toggled {
      background-color: $color-F8F8F8;
    }
  }
}

.rx-popup {
  max-height: max-content !important;

  &[data-rx-popup-name='addbar'] {
    width: auto !important;
    max-width: 380px;
  }

  .rx-popup-header-box {
    font-size: 18px;
  }

  .rx-popup-footer {
    display: flex;
    justify-content: flex-end;

    button[data-name='cancel'] {
      order: -1;
    }
  }

  .rx-popup-item {
    &:hover {
      background-color: $color-F8F8F8 !important;
    }

    &.active {
      background-color: $color-F8F8F8;
      color: $color-282525 !important;

      span {
        color: $color-282525 !important;
      }
    }
  }

  .rx-form-label {
    font-size: 14px;
    color: $color-525050 !important;
    font-weight: 500 !important;
  }
}

.rx-form-button {
  border-radius: 6px !important;
  border: 1px solid $color-525050 !important;
  background-color: $color-FFFFFF !important;
  color: $color-525050 !important;
  font-weight: 600 !important;

  &.rx-form-button-primary {
    border-color: $color-E30613 !important;
    background-color: $color-E30613 !important;
    color: $color-FFFFFF !important;

    &:hover {
      background-color: $color-CD0511 !important;
    }

    &.is-disabled,
    &[disabled] {
      background-color: $color-D2D1D1 !important;

      .icon {
        background-color: $color-FFFFFF;
      }
    }

    .icon {
      background-color: $color-FFFFFF;
    }
  }
}

.rx-content {
  word-break: break-word;

  ul,
  ol {
    list-style: revert;
  }

  b,
  strong {
    font-weight: 700 !important;
  }

  i {
    font-style: italic;
  }
}

.chat-message-input.has-attachments {
  .rx-content {
    padding: 20px 20px 80px 20px !important;
  }
}
