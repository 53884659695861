@use 'variables' as *;
@use 'colors' as *;

body,
button,
input,
textarea {
  font-family: $default-font-family;
  font-size: $default-font-size;
  font-weight: $default-font-weight;
  color: $default-font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @extend .title-1;
}

h2 {
  @extend .title-2;
}

h3 {
  @extend .title-3;
}

h4 {
  @extend .title-4;
}

h5 {
  @extend .title-5;
}

h6 {
  @extend .title-6;
}

.title-1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.title-2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.title-3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.title-4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.title-5 {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.title-6 {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.caption-1 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.caption-2 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}
