@use 'src/styles/base/colors' as *;

.tooltip {
  color: $color-FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  position: absolute;
  max-width: 340px;
  min-height: 30px;
  background: rgba(82, 80, 80, 0.9);
  border-radius: 10px;
  bottom: 4px;
  line-height: 18px;

  &.bs-tooltip-top,
  &.bs-tooltip-bottom {
    padding: 8px 10px;
  }
}
