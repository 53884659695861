.modal {
  .modal-content {
    &.has-content-side-padding {
      .modal-scrollbar {
        .ng-scrollbar-wrapper[deactivated='false'] {
          & > scrollbar-y {
            &.scrollbar-control {
              right: -20px;
            }
          }
        }
      }
    }
  }
}

.page-layout-main-scrollbar {
  .page-layout-main-viewport {
    > .ng-scroll-content {
      display: grid;
      min-height: 100%;
    }
  }
}
