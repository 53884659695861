@use 'src/styles/base/colors' as *;
@use 'src/styles/base/variables' as *;

@mixin icon-size($width, $height: $width) {
  width: $width;
  height: $height;
  mask-size: $width $height;
}

@mixin font-size-small {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

@mixin font-size-medium-large {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

@mixin paginator-page {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  border-radius: 6px;
  min-width: $paginator-button-width;
  height: $paginator-button-height;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 calc(5px / 2);
  background-color: $color-F1F1F1;
  color: $color-282525;
}

@mixin modal-form-container {
  .form-section {
    padding-left: 20px;
    padding-right: 20px;

    &:first-child {
      padding-bottom: 20px;
      border-bottom: 1px solid $color-E7E7E7;
    }

    &:not(:first-child) {
      padding-top: 20px;
    }

    .form-control {
      ::ng-deep {
        .input-suffix {
          width: 80px;
        }
      }
    }

    .form-section-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: $color-282525;
      margin-bottom: 20px;
    }

    .form-section-fields {
      grid-template-columns: repeat(4, minmax(275px, 1fr));
      grid-gap: 20px;
      display: grid;
    }
  }
}

@mixin modal-form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .required {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $color-A7A6A6;

    i {
      color: $color-E30613;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
  }

  .button {
    &:first-child {
      margin-right: 10px;
    }
  }
}

@mixin list-item-details {
  &.is-blocked {
    .detail {
      &.is-last-row {
        border-bottom: 1px solid $color-F1F1F1;
      }

      &.blockage-reason {
        display: grid;
        grid-area: blockage-reason;
        grid-template-columns: minmax($table-details-column-min-width, 150px) 1fr;
        text-align: justify;
        word-break: break-word;
      }
    }
  }

  .heading {
    @extend .caption-1;

    display: block;
    margin-bottom: 18px;
  }

  .details {
    display: grid;
    grid-template-columns: repeat(3, minmax($table-details-column-min-width, 1fr));
    gap: 0 20px;
    align-items: flex-start;
    justify-content: space-between;
  }

  .detail {
    display: grid;
    grid-template-columns: repeat(2, minmax($table-details-column-min-width, 150px));
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    padding: 12px 0;
    border-bottom: 1px solid $color-F1F1F1;

    &.is-last-row,
    &:last-child {
      border-bottom: none;
    }
  }

  .content {
    padding: 6px 20px 0;
    border-bottom: 1px solid $color-E7E7E7;
  }

  .blockage-reason {
    display: none;
  }

  .list-item-actions {
    display: flex;
    gap: 0 10px;
  }
}

@mixin auth-page-layout {
  .auth-page-layout {
    display: flex;
    flex-flow: column;
    align-items: center;

    .logo-wrapper {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      .logo-n-color {
        width: 138px;
        margin-bottom: 20px;
      }
    }

    .message {
      color: $color-7D7B7B;
      margin: 10px 0;
      padding: 0 70px;
      text-align: center;
    }

    .form {
      margin-top: 5px;
      display: block;
      width: 100%;
    }
  }
}
