@use 'src/styles/base/colors' as *;
@use 'src/styles/forms/forms-mixins' as *;
@use 'src/styles/forms/inputs' as *;

.form-control-textarea {
  height: 100%;

  &.is-focus {
    .textarea {
      border-color: $color-E30613;
    }
  }

  &.is-error {
    .textarea {
      border-color: $color-FF3A0F;
    }
  }
}

.textarea {
  @include input;

  width: 100%;
  height: 100%;
  padding: 8px 10px;
  line-height: 20px;
  resize: none;
  display: block;
  border-radius: 12px;
  border: 1px solid $color-D2D1D1;
}
