@use 'src/styles/base/colors' as *;
@use 'src/styles/base/mixins' as *;

.icon-workshop-7D7B7B-24x24 {
  mask: url('../../assets/icons/workshops/workshops-black-24x24.svg');
  background-color: $color-7D7B7B;
}

.icon-workshop-FFFFFF-24x24 {
  mask: url('../../assets/icons/workshops/workshops-black-24x24.svg');
  background-color: $color-FFFFFF;
}

.icon-users-7D7B7B-24x24 {
  mask: url('../../assets/icons/users/users-black-24x24.svg');
  background-color: $color-7D7B7B;
}

.icon-users-FFFFFF-24x24 {
  mask: url('../../assets/icons/users/users-black-24x24.svg');
  background-color: $color-FFFFFF;
}

.icon-users-E30613-24x24 {
  mask: url('../../assets/icons/users/users-black-24x24.svg');
  background-color: $color-E30613;
}

.icon-news-7D7B7B-24x24 {
  mask: url('../../assets/icons/news/news-black-24x24.svg');
  background-color: $color-7D7B7B;
}

.icon-news-FFFFFF-24x24 {
  mask: url('../../assets/icons/news/news-black-24x24.svg');
  background-color: $color-FFFFFF;
}

.icon-comment-7D7B7B-24x24 {
  mask: url('../../assets/icons/changelog/comment-white-24x24.svg');
  background-color: $color-7D7B7B;
}

.icon-comment-FFFFFF-24x24 {
  mask: url('../../assets/icons/changelog/comment-white-24x24.svg');
  background-color: $color-FFFFFF;
}

.icon-double-chevron-left-E30613-20x20 {
  mask: url('../../assets/icons/arrows/double-chevron-left-black-24x24.svg');
  background-color: $color-E30613;
  opacity: 0.4;

  @include icon-size(20px);
}

.icon-double-chevron-right-E30613-20x20 {
  mask: url('../../assets/icons/arrows/double-chevron-left-black-24x24.svg');
  background-color: $color-E30613;
  opacity: 0.4;
  transform: rotate(180deg);

  @include icon-size(20px);
}

.icon-user-525050-24x24 {
  mask: url('../../assets/icons/users/user-black-24x24.svg');
  background-color: $color-525050;
}

.icon-user-7D7B7B-24x24 {
  mask: url('../../assets/icons/users/user-black-24x24.svg');
  background-color: $color-7D7B7B;
}

.icon-users-FFFFFF-24x24 {
  mask: url('../../assets/icons/users/users-black-24x24.svg');
  background-color: $color-7D7B7B;
}

.icon-users-FFFFFF-24x24 {
  mask: url('../../assets/icons/users/users-black-24x24.svg');
  background-color: $color-FFFFFF;
}

.icon-users-E30613-18x18 {
  mask: url('../../assets/icons/users/users-black-24x24.svg');
  background-color: $color-E30613;

  @include icon-size(18px);
}

.icon-eye-on-525050-24x24 {
  mask: url('../../assets/icons/eyes/eye-on-black-24x24.svg');
  background-color: $color-525050;
}

.icon-eye-off-525050-24x24 {
  mask: url('../../assets/icons/eyes/eye-off-black-24x24.svg');
  background-color: $color-525050;
}

.icon-close-525050-18x18 {
  mask: url('../../assets/icons/close/close-black-24x24.svg');
  background-color: $color-525050;

  @include icon-size(18px);
}

.icon-close-525050-10x10 {
  mask: url('../../assets/icons/close/close-black-24x24.svg');
  background-color: $color-525050;

  @include icon-size(10px);
}

.icon-close-A7A6A6-20x20 {
  mask: url('../../assets/icons/close/close-black-24x24.svg');
  background-color: $color-A7A6A6;

  @include icon-size(20px);
}

.icon-close-525050-24x24 {
  mask: url('../../assets/icons/close/close-black-24x24.svg');
  background-color: $color-525050;

  @include icon-size(24px);
}

.icon-close-A7A6A6-24x24 {
  mask: url('../../assets/icons/close/close-black-24x24.svg');
  background-color: $color-A7A6A6;
}

.icon-close-small-A7A6A6-20x20 {
  mask: url('../../assets/icons/close/close-small-black-24x24.svg');
  background-color: $color-A7A6A6;

  @include icon-size(20px);
}

.icon-close-small-FFFFFF-20x20 {
  mask: url('../../assets/icons/close/close-small-white-20x20.svg');

  @include icon-size(20px);
}

.icon-close-small-525050-24x24 {
  mask: url('../../assets/icons/close/close-small-black-24x24.svg');
  background-color: $color-525050;

  @include icon-size(24px);
}

.icon-close-A7A6A6-12x12 {
  mask: url('../../assets/icons/close/close-black-24x24.svg');
  background-color: $color-A7A6A6;

  @include icon-size(12px);
}

.icon-chevron-up-small-282525-24x24 {
  mask: url('../../assets/icons/arrows/chevron-up-small-black-24x24.svg');
  background-color: $color-282525;

  @include icon-size(24px);
}

.icon-chevron-down-small-282525-24x24 {
  mask: url('../../assets/icons/arrows/chevron-down-small-black-24x24.svg');
}

.icon-chevron-down-small-525050-18x18 {
  mask: url('../../assets/icons/arrows/chevron-down-small-black-24x24.svg');
  background-color: $color-525050;

  @include icon-size(18px);
}

.icon-chevron-down-small-FFFFFF-18x18 {
  mask: url('../../assets/icons/arrows/chevron-down-small-black-24x24.svg');
  background-color: $color-FFFFFF;

  @include icon-size(18px);
}

.icon-chevron-down-small-525050-24x24 {
  mask: url('../../assets/icons/arrows/chevron-down-small-black-24x24.svg');
  background-color: $color-525050;

  @include icon-size(24px);
}

.icon-chevron-left-small-7D7B7B-28x28 {
  mask: url('../../assets/icons/arrows/chevron-left-small-black-24x24.svg');
  background-color: $color-7D7B7B;

  @include icon-size(28px);
}

.icon-chevron-right-small-7D7B7B-28x28 {
  mask: url('../../assets/icons/arrows/chevron-right-small-black-24x24.svg');
  background-color: $color-7D7B7B;

  @include icon-size(28px);
}

.icon-chevron-left-small-7D7B7B-12x12 {
  mask: url('../../assets/icons/arrows/chevron-right-small-black-24x24.svg');
  background-color: $color-7D7B7B;

  @include icon-size(12px);
}

.icon-chevron-right-small-FFFFFF-24x24 {
  mask: url('../../assets/icons/arrows/chevron-right-small-black-24x24.svg');
  background-color: $color-FFFFFF;
}

.icon-image-placeholder-D2D1D1-80x80 {
  mask: url('../../assets/icons/placeholders/image-placeholder-gray-60x60.svg');
  background-color: $color-D2D1D1;

  @include icon-size(80px);
}

.icon-add-E30613-18x18 {
  mask: url('../../assets/icons/add/add-black-24x24.svg');
  background-color: $color-E30613;

  @include icon-size(18px);
}

.icon-add-525050-30x30 {
  mask: url('../../assets/icons/add/add-black-24x24.svg');
  background-color: $color-525050;

  @include icon-size(30px);
}

.icon-search-FFFFFF-18x18 {
  mask: url('../../assets/icons/search/search-black-24x24.svg');
  background-color: $color-FFFFFF;

  @include icon-size(18px);
}

.icon-search-525050-16x16 {
  mask: url('../../assets/icons/search/search-black-24x24.svg');
  background-color: $color-282525;

  @include icon-size(16px);
}

.icon-calendar-A7A6A6-24x24 {
  mask: url('../../assets/icons/calendars/calendar-black-24x24.svg');
  background-color: $color-A7A6A6;

  @include icon-size(24px);
}

.icon-trash-525050-18x18 {
  mask: url('../../assets/icons/remove/trash-black-24x24.svg');
  background-color: $color-525050;

  @include icon-size(18px);
}

.icon-trash-525050-24x24 {
  mask: url('../../assets/icons/remove/trash-black-24x24.svg');
  background-color: $color-525050;

  @include icon-size(24px);
}

.icon-edit-A7A6A6-12x12 {
  mask: url('../../assets/icons/edit/edit-black-24x24.svg');
  background-color: $color-A7A6A6;

  @include icon-size(12px);
}

.icon-edit-square-FFFFFF-18x18 {
  mask: url('../../assets/icons/edit/edit-square-black-24x24.svg');
  background-color: $color-FFFFFF;

  @include icon-size(18px);
}

.icon-refresh-FFFFFF-18x18 {
  mask: url('../../assets/icons/workshops/refresh-white-18x18.svg');
  background-color: $color-FFFFFF;

  @include icon-size(18px);
}

.icon-copy-FFFFFF-18x18 {
  mask: url('../../assets/icons/workshops/copy-red-18x18.svg');
  background-color: $color-FFFFFF;

  @include icon-size(18px);
}

.icon-edit-square-525050-24x24 {
  mask: url('../../assets/icons/edit/edit-square-black-24x24.svg');
  background-color: $color-525050;

  @include icon-size(24px);
}

.icon-check-E30613-20x20 {
  mask: url('../../assets/icons/check/check-black-24x24.svg');
  background-color: $color-E30613;

  @include icon-size(20px);
}

.icon-check-FFFFFF-22x22 {
  mask: url('../../assets/icons/check/check-black-24x24.svg');
  background-color: $color-FFFFFF;

  @include icon-size(22px);
}

.icon-check-E30613-24x24 {
  mask: url('../../assets/icons/check/check-black-24x24.svg');
  background-color: $color-E30613;

  @include icon-size(22px);
}

.icon-success-0C9D58-140x140 {
  mask: url('../../assets/icons/check/check-circle-green-filled-24x24.svg');
  background-color: $color-0C9D58;

  @include icon-size(140px);
}

.icon-logout-0C9D58-18x18 {
  mask: url('../../assets/icons/logout/logout-black-18x18.svg');
}

.icon-search-FFFFFF-40x40 {
  mask: url('../../assets/icons/search/search-white-40x40.svg');
}

.icon-info-FFFFFF-40x40 {
  mask: url('../../assets/icons/infos/info-white-40x40.svg');
}

.icon-empty-D2D1D1-80x80 {
  mask: url('../../assets/icons/empty/empty-grey-80x80.svg');
  background-color: $color-D2D1D1;

  @include icon-size(80px);
}

.icon-bar-chart-7D7B7B-24x24 {
  mask: url('../../assets/icons/bar-chart/bar-chart-black-24x24.svg');
  background-color: $color-7D7B7B;

  @include icon-size(24px);
}

.icon-drag-indicator-E30613-14x20 {
  mask: url('../../assets/icons/drag-indicator/drag-indicator-E30613-14x20.svg');
}

.icon-download-FFFFFF-18x18 {
  mask: url('../../assets/icons/download/download-525050-24x24.svg');
  background-color: $color-FFFFFF;

  @include icon-size(18px);
}
